import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ExclaimerMigrationObject } from '../models/exclaimerMigrationObject';
import { ExclaimerMigrationRequestDTO } from '../models/DTO/exclaimerMigrationRequestDTO';
import { constants } from 'http2';

@Injectable({
  providedIn: 'root',
})
export class ExclaimerPartnerService {
  private readonly exclaimerApi: string = environment.exclaimerApiUrl + '/Partner';

  constructor(private http: HttpClient) {}

  makePartnerReseller(customEmail: string, partnerId: string) {
    return this.http.post(this.exclaimerApi + '/Create/New/' + partnerId, {
      customEmail,
    });
  }

  partnerMigrationRequest(partnerId: string, migrationInfo: ExclaimerMigrationRequestDTO): Observable<any> {
    return this.http.post(this.exclaimerApi + '/' + partnerId + '/Migration/AttachExisting', migrationInfo);
  }

  getPartnerMigrationDetails(partnerId: string): Observable<ExclaimerMigrationObject> {
    return this.http.get<ExclaimerMigrationObject>(this.exclaimerApi + '/' + partnerId + '/Migration/Details');
  }

  confirmMigrationToCloudfactory(partnerId: string): Observable<any> {
    return this.http.put(this.exclaimerApi + '/' + partnerId + '/Migration/ConfirmMove', null);
  }

  getPartnerSignupMail(partnerId: string): Observable<any> {
    return this.http.get(this.exclaimerApi + '/' + partnerId + '/SignupEmail');
  }
}
